import React from 'react';
import './App.css'; // You can customize or use a similar CSS file for styling
import website from './components/website';
import { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      if (window.scrollY >= 2) {
        document.querySelector('.ds-header').classList.add('ds-fixed-header');
      } else {
        document.querySelector('.ds-header').classList.remove('ds-fixed-header');
      }
    };

    // Set the CSS variable dynamically based on header height
    const setMastheadHeight = () => {
      const mastheadHeight = document.querySelector('.ds-header').offsetHeight;
      document.documentElement.style.setProperty('--masthead-height', `${mastheadHeight}px`);
    };

    // Run both functions on component mount
    setMastheadHeight();
    handleScroll();

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
<div>
  <>
{/* Required meta tags */}
<meta charSet="utf-8" />
<meta name="viewport" content="width=device-width, initial-scale=1" />
{/* Google Fots */}
<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
<link
 href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
 rel="stylesheet"
/>
{/* Remixicon Icon */}
<link
 href="https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css"
 rel="stylesheet"
/>
{/* Remixicon Icon */}
{/* Bootstrap CSS */}
<link
 href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
 rel="stylesheet"
 integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
 crossOrigin="anonymous"
/>
<link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet" />
{/* Main CSS */}
<link href="assets/css/main.css" rel="stylesheet" />
<title>Akshat Goyal</title>
<header className="ds-header" id="site-header">
 <div className="container">
   <div className="ds-header-inner">
     <a href="index.html" className="ds-logo">
       Akshat Goyal
     </a>
     <ul className="ds-social">
       <li>
         <a href="#" target="_blank">
           <i className="ri-instagram-fill" />
         </a>
       </li>
       <li>
         <a href="#" target="_blank">
           <i className="ri-github-fill" />
         </a>
       </li>
       <li>
         <a
           href="https://www.linkedin.com/in/akshat-goyal-424aba241/"
           target="_blank"
         >
           <i className="ri-linkedin-fill" />
         </a>
       </li>
     </ul>
   </div>
 </div>
</header>
<div className="ds-banner">
 <div className="container">
   <div className="row">
     <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
       <figure>
         <img src="/Images/PXL_20240110_101755980.MP.jpg" />
       </figure>
     </div>
     <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
       <section>
         <h1>
           <span>HI THERE</span>
           I’m Akshat Goyal <br /> UI/UX Designer + Graphic Designer based in
           Delhi
         </h1>
         <ul className="ds-numbervalulist">
           <li>
             <strong>4+</strong>
             <span>Years experience</span>
           </li>
           <li>
             <strong>15+</strong>
             <span>Certifications</span>
           </li>
           <li>
             <strong>5+</strong>
             <span>Project</span>
           </li>
         </ul>
       </section>
     </div>
   </div>
 </div>
</div>
<div className="ds-about-section">
 <div className="container">
   <section>
     <h2 className="ds-heading">About Me</h2>
     <p>
       {" "}
       Currently in the final year of my BTech degree at Vellore Institute of
       Technology, I am working as a designer with a strong interest in
       UI/UX. My creative mindset allows me to devise innovative solutions to
       problems, aiming to create user-friendly and visually appealing
       products and services that positively impact the world. Additionally,
       I have been actively involved in the field of Artificial Intelligence
       and Machine Learning, where I leverage my technical skills to develop
       intelligent systems that enhance user experiences
     </p>
     <div className="ds-button-sec text-center">
       <a href="Images/Resume Akshat.pdf" className="ds-button">
         Download Resume
       </a>
     </div>
   </section>
 </div>
</div>
<div className="ds-skills-section">
 <div className="container">
   <div className="row">
     <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
       <h2 className="ds-heading">Technical Skills</h2>
       <ul className="ds-skills-list">
         <li>JavaScript</li>
         <li>HTML</li>
         <li>Bootstrap</li>
         <li>Python</li>
         <li>Matlab</li>
         <li>CSS</li>
         <li>Github</li>
         <li>HTML</li>
       </ul>
     </div>
     <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
       <h2 className="ds-heading">Design Skills</h2>
       <ul className="ds-skills-list">
         <li>Figma</li>
         <li>Adobe Photoshop</li>
         <li>Adobe Illustrator</li>
         <li>Adobe Premiere Pro</li>
       </ul>
     </div>
   </div>
 </div>
</div>
{/*  Skills */}
{/* Experience */}
<div className="ds-experience-section">
 <div className="container">
   <h2 className="ds-heading">Work Experience</h2>
   <div className="row ds-experience-list">
     <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
       <section>
         <span className="ds-year">2023 - Present</span>
         <h3 className="ds-officename">Collab Junction</h3>
         <span className="ds-department">Head of Design</span>
         <p>
           Designed visually captivating branding materials, including
           posters, websites, and marketing collateral, to effectively
           communicate the startup's unique identity and values. Employed a
           strategic approach to align design elements with the company's
           brand vision, resulting in increased brand recognition and
           customer engagement
         </p>
       </section>
     </div>
     <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
       <section>
         <span className="ds-year">2023 - 2023</span>
         <h3 className="ds-officename">Maruti Suzuki</h3>
         <span className="ds-department">Research Intern</span>
         <p>
           Conducted in-depth research and analysis on antenna design and
           shark-fin technology, contributing insights that informed product
           development decisions. Additionally, facilitated a comprehensive
           training session on Figma, equipping team members with essential
           design tools and methodologies to enhance collaboration and
           streamline the design process
         </p>
       </section>
     </div>
     <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
       <section>
         <span className="ds-year">2023 - 2023</span>
         <h3 className="ds-officename">AMD (Advanced Micro Devices)</h3>
         <span className="ds-department">Intern</span>
         <p>
           Executed graphic design projects and web development tasks for
           AMD, leveraging industry-leading software and technologies to
           produce visually engaging marketing materials and user-friendly
           website interfaces. Obtained valuable insights into the
           intersection of design and technology within the semiconductor
           industry, contributing to innovative solutions and enhancing the
           company's digital presence and brand identity
         </p>
       </section>
     </div>
   </div>
 </div>
</div>
{/* Experience */}
{/*  Work */}
<div className="ds-work-section">
 <div className="container">
   <h2 className="ds-heading">Latest works</h2>
   <div className="ds-work-list-section">
     <div className="ds-work-list">
       <div className="row">
         <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
           <section>
             <h3 className="ds-work-tilte">Art Gallery app</h3>
             <p>
               The art gallery app is a great way for people of all ages and
               backgrounds to learn about and enjoy art and buy/sell art. It
               is a valuable tool for art enthusiasts, casual art lovers,
               families and children, tourists, and anyone else who is
               interested in learning more about art.{" "}
             </p>
             <a
               href="https://prickle-mallet-454.notion.site/Aviato-112f42faa86a411bbc4e090b963792fe?pvs=4"
               className="ds-button"
             >
               Details
             </a>
           </section>
         </div>
         <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
           <figure>
             <img src="Images/Untitled design (1).png" />
           </figure>
         </div>
       </div>
     </div>
     <div className="ds-work-list">
       <div className="row">
         <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
           <section>
             <h3 className="ds-work-tilte">Collaboration Platform </h3>
             <p>
               A website for all the collaboration tools needed and a
               an innovative platform designed to streamline the process of discovering, booking, and managing speakers and artists for events. It aims to bridge the gap between event organizers and talent by offering a centralized, user-friendly solution that simplifies every aspect of event talent management—from initial search to contract finalization{" "}
             </p>
             <a
               href="https://prickle-mallet-454.notion.site/Collab-Junction-700a3137f89e4c4db27e65fa760c842e?pvs=74"
               className="ds-button"
             >
               Details
             </a>
           </section>
         </div>
         <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
           <figure>
             <img src="Images/partner up.png" />
           </figure>
         </div>
       </div>
     </div>
     <div className="ds-work-list">
       <div className="row">
         <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
           <section>
             <h3 className="ds-work-tilte">Graphic Designs</h3>
             <p>
               All my Graphic Designs curated in a Playbook site,made with
               the modern tools such as Photoshop
             </p>
             <a
               href="https://www.playbook.com/s/akshatgoyal/porfolio"
               className="ds-button"
             >
               Details
             </a>
           </section>
         </div>
         <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
           <figure>
             <img src="Images/Graphic Designs.png" />
           </figure>
         </div>
       </div>
     </div>
   </div>
 </div>
</div>
{/*  Work */}
{/*  footer 
<footer class="ds-footer text-center">
  <div class="container">
<section>
 <h4>Ready to talk?</h4>
 <p>Feel free to contact me</p>
 <a href="mailto:writetoakshatgoyal@gmail.com" class="ds-button">Mail</a>
</section>
</footer>*/}
{/* Option 1: Bootstrap Bundle with Popper */}
</>

<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
 <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

 <script src="Images/main.js"></script>
 </div>
 </div>

  );
};

export default App;

